import React, { useEffect, useState } from 'react';

import styles from './cardObjectiveAdventure.module.scss';
import { appImageAssets } from '../../../data/enums/appImageAssets';
import SelectModal from '../../Modal/SelectModal/SelectModal';
import { getObjectives } from '../../../libs/gamification/missions';
import ArrowIndicator from '../../Arrows/ArrowIndicator/ArrowIndicator';

const CardObjectiveAdventure = ({ mainObjective = {}, updateMainObjective = () => {} }) => {
    const [show, setShow] = useState(false);
    const [objectives, setObjectives] = useState([]);

    useEffect(() => {
        let isMounted = true;
        const fetchObjectives = async () => {
            const res = await getObjectives();

            if (!isMounted) return;
            setObjectives(
                res.map((el) => {
                    return {
                        objective: el,
                        selected: false,
                    };
                }),
            );
        };

        fetchObjectives();

        return () => {
            isMounted = false;
        };
    }, []);

    return (
        <div className={styles.card}>
            <div className={styles.icon}>
                <img src={appImageAssets.icon.objectiveAdventure} alt="" />
            </div>
            <div className={styles.content}>
                <div className={styles.title}>
                    <img src={appImageAssets.icon.objectiveAdventure} className={styles.isMobile} alt="" />
                    <h2>Objectifs de votre aventure</h2>
                </div>
                <div className={`${styles.mainObjective} ${styles.objectives}`}>
                    <p>Objectif activé</p>
                    <div style={{ position: 'relative' }}>
                        <div className={styles.pill} onClick={() => setShow(true)}>
                            <span>
                                {mainObjective?.name ? mainObjective.name : 'Veuillez sélectionner un objectif'}
                            </span>
                            <ArrowIndicator active={show} />
                        </div>
                        <SelectModal onClose={() => setShow(false)} show={show}>
                            {objectives?.length !== 0 &&
                                objectives.map((obj, index) => {
                                    if (mainObjective?.name === obj?.objective.name) return null;
                                    return (
                                        <span
                                            key={index}
                                            onClick={() => {
                                                updateMainObjective(obj.objective);
                                                setShow(false);
                                            }}
                                        >
                                            {obj.objective.name}
                                        </span>
                                    );
                                })}
                        </SelectModal>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardObjectiveAdventure;
