import React, { useEffect, useState } from 'react';
import styles from './TaskPresetCard.module.scss';
import TextContainer from '../../../Text/TextContainer/TextContainer';
import { MISSION_TASK_PICTOS } from '../../../../libs/gamification/pictos';
import { appImageAssets } from '../../../../data/enums/appImageAssets';
import Loader from '../../../Loaders/Loader/Loader';
import CardBgBottom from '../../CardBgBottom/CardBgBottom';
import ImageBorder from '../../../Image/ImageBorder/ImageBorder';
import NoticePriceService from '../../../Notices/NoticePriceService/NoticePriceService';
import { generateCozyLink } from '../../../../utils/cozy';
import { config } from '../../../../config';
import { openWindow } from '../../../../utils/openWindow';
import GenericModal from '../../../Modal/GenericModal/GenericModal';
import { useAuth } from '../../../../hooks/useAuth';
import { useTaskServices } from '../../../../hooks/useTaskServices';
import SummaryVisionsStars from '../../../VisionsStars/SummaryVisionsStars/SummaryVisionsStars';
import { useNavigate } from 'react-router-dom';
import { appLinks } from '../../../../data/enums/appLinks';
import { useMissionMethods } from '../../../../hooks/useMissionMethods';
import { useUserMissionsMethods } from '../../../../hooks/useUserMissionsMethods';
import { Button } from '../../../Buttons/Button/Button';
import { Icon } from '../../../Icons/Icon/Icon';
import { ButtonContainer } from '../../../Containers/ButtonContainer/ButtonContainer';

const TaskPresetCard = ({
    missionTask,
    missionTaskCompleted,
    missionTaskNumber,
    servicesCompleted,
    onClick,
    updatePrecisionLevel,
    updateErrorMessage,
    errorMessage,
    actions,
}) => {
    const { user } = useAuth();
    const { completeTask } = useMissionMethods();
    const { addAction } = useUserMissionsMethods();

    const navigate = useNavigate();

    const [toggle, setToggle] = useState(false);
    const [selectedServiceUrl, setSelectedServiceUrl] = useState('');
    const [preRedirectionModalState, setPreRedirectModalState] = useState(false);
    const [currentServiceId, setCurrentServiceId] = useState('');
    const [animationPulse, setAnimationPulse] = useState(false);

    const [taskCompleted, setTaskCompleted] = useState(missionTaskCompleted);

    const [, servicesLoading, services] = useTaskServices({
        taskId: missionTask?.action === 'toggle' ? missionTask?._id : null,
    });

    const generateSelectedServiceUrl = (service) => {
        if (service?.name?.toLowerCase()?.includes('cozy'))
            setSelectedServiceUrl(generateCozyLink(user._id, user.email));
        else setSelectedServiceUrl(service?.urls?.website);
    };

    /**
     * Verifies if the service was visited from the task actions completion
     * @param {string} serviceId The service ID to check
     */
    const checkVisitedService = (serviceId) => {
        if (!actions.length) return false;
        return !!actions.find((action) => action.resource === serviceId);
    };

    const buttonActionTask = async () => {
        switch (missionTask?.action) {
            case 'toggle':
                setToggle((prev) => !prev);
                if (animationPulse) {
                    setAnimationPulse(false);
                }
                return;
            case 'choice':
                navigate(appLinks.experiences.parcoursup);
                return;
            case 'iframe':
                if (servicesCompleted === 0) {
                    updateErrorMessage(true);
                    setTimeout(() => {
                        updateErrorMessage(false);
                    }, 2000);
                    return;
                }

                try {
                    if (!missionTaskCompleted) {
                        await completeTask(missionTask?._id);
                        setTaskCompleted(true);
                    }
                    await addAction(missionTask?._id, { type: 'consent', resource: 'headai_matching_iframe' });
                } catch (err) {
                    console.log(err);
                }
                if (onClick) onClick();
                return;

            default:
                navigate(appLinks.experiences.index);
                return;
        }
    };

    const TMP_taskAmountFromContext = () => {
        const localConfig = localStorage.getItem('headai');
        if (localConfig && JSON.parse(localConfig)?.namespace === 'sdn') return '1 outil qui répond à vos besoins.';
        return '2 outils qui répondent à vos besoins.';
    };

    useEffect(() => {
        if (servicesCompleted > 0) updateErrorMessage(false);
    }, [servicesCompleted, updateErrorMessage]);

    useEffect(() => {
        let precisionLevel = 0;
        actions.forEach((action) => {
            services.forEach((service) => {
                if (service?._id === action?.resource) precisionLevel++;
            });
        });
        if (precisionLevel > 0) updatePrecisionLevel(precisionLevel);
    }, [actions, services, updatePrecisionLevel]);

    useEffect(() => {
        if (!errorMessage) return;

        if (missionTask?.action === 'toggle') {
            const element = document.getElementById('container');

            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            } else {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            }
        }

        setAnimationPulse(true);
    }, [errorMessage, missionTask]);

    if (!missionTask) return <Loader />;

    return (
        <div className={styles.container} id="container">
            <div className={styles.task}>
                <div className={styles.information}>
                    <div className={styles.logo}>
                        <img src={MISSION_TASK_PICTOS[missionTask?.icon]} alt="Icone de tâche" />
                        <img
                            className={`${styles.check} ${taskCompleted ? styles.checked : ''}`}
                            src={taskCompleted ? appImageAssets.icon.check : ''}
                            alt=""
                        />
                    </div>
                    <div className={`${styles.description}`}>
                        <React.Fragment>
                            <p className={styles.taskName}>TÂCHE {missionTaskNumber}</p>
                            <div className={styles.accomplishMobile}>
                                <Button
                                    animation={missionTask?.action === 'toggle' && animationPulse ? 'pulse' : null}
                                    onclick={() => buttonActionTask()}
                                >
                                    {missionTask?.action === 'iframe' ? (
                                        'voir mes matchs'
                                    ) : toggle ? (
                                        <Icon icon="angleTop" />
                                    ) : (
                                        'accomplir'
                                    )}
                                </Button>
                                {errorMessage && missionTask?.action === 'iframe' && (
                                    <p className={styles.errorMessage}>Complétez la mission 1 pour voir vos matchs !</p>
                                )}
                            </div>
                            <div className={styles.referenceMobile}>
                                <SummaryVisionsStars remove={taskCompleted} visionsStars={missionTask?.visionsStars} />
                            </div>
                            <p className={styles.descriptionText}>{missionTask?.name}</p>
                        </React.Fragment>
                    </div>
                </div>
                <div className={styles.reference}>
                    <SummaryVisionsStars
                        remove={taskCompleted}
                        visionsStars={missionTask?.visionsStars}
                        sx={{ margin: '0 50px' }}
                    />
                </div>
                <div className={styles.accomplish}>
                    <Button
                        animation={missionTask?.action === 'toggle' && animationPulse ? 'pulse' : null}
                        onclick={() => buttonActionTask()}
                    >
                        {missionTask?.action === 'iframe' ? (
                            'voir mes matchs'
                        ) : toggle ? (
                            <Icon icon="angleTop" />
                        ) : (
                            'accomplir'
                        )}
                    </Button>
                    {errorMessage && missionTask?.action === 'iframe' && (
                        <p className={styles.errorMessage}>Complétez la mission 1 pour voir vos matchs !</p>
                    )}
                </div>
            </div>
            {toggle && (
                <div className={styles.toggleContainer}>
                    {!taskCompleted && (
                        <TextContainer className={styles.toggleContainerInfo}>
                            Pour réaliser cette tâche nous vous proposons {TMP_taskAmountFromContext()}
                            Choississez l'outil qui vous intéresse et gagnez
                            <SummaryVisionsStars visionsStars={missionTask?.visionsStars} sx={{ margin: 5 }} />
                        </TextContainer>
                    )}

                    <div className={styles.servicesContainer}>
                        {servicesLoading && <Loader />}
                        {services?.length !== 0 &&
                            services.map((service, index) => (
                                <CardBgBottom key={index} animation={'hoverBlue'} color={'blue'}>
                                    <div className={styles.contentCard}>
                                        <div className={styles.edtech}>
                                            <div className={styles.header}>
                                                <ImageBorder
                                                    image={`${config.logoBaseUrl}/${service?.logo}`}
                                                    alt={`Logo ${service?.name}`}
                                                    size={'xs'}
                                                />
                                                <h4>{service.name}</h4>
                                            </div>
                                            <div className={styles.content}>
                                                <ul className={styles.tags}>
                                                    {service?.galaxy?.user?.tags.map((tag) => (
                                                        <li key={tag}>{tag}</li>
                                                    ))}
                                                </ul>
                                                <p className={styles.textInfo}>
                                                    {service?.galaxy?.user?.description || service?.description}
                                                </p>
                                            </div>
                                        </div>
                                        <div className={styles.btn}>
                                            {service?.galaxy?.user?.isFree === false && <NoticePriceService />}
                                            <ButtonContainer>
                                                <Button
                                                    endIcon={<Icon icon="arrow" />}
                                                    sx={{ fontWeight: 'bold' }}
                                                    onclick={() => {
                                                        setCurrentServiceId(service?._id);
                                                        setPreRedirectModalState(true);
                                                        generateSelectedServiceUrl(service);
                                                    }}
                                                >
                                                    {checkVisitedService(service?._id) ? 'Rejouer' : 'Commencer'}
                                                </Button>
                                            </ButtonContainer>
                                        </div>
                                    </div>
                                </CardBgBottom>
                            ))}
                    </div>
                </div>
            )}
            <GenericModal
                open={preRedirectionModalState}
                closeModal={() => setPreRedirectModalState(false)}
                image={appImageAssets.icon.redirect}
            >
                <div className={styles.contentModal}>
                    <p>
                        Explorer cet outil pour progresser dans cette tâche et gagnez des VisionsStars. <br></br>
                        <br></br> Une fois, votre exploration de cet outil complétée, n'oubliez pas de revenir ici pour
                        vos matchs !
                    </p>
                    <ButtonContainer margin={'10px 0 0 0'}>
                        <Button
                            endIcon={<Icon icon="arrow" />}
                            size="main"
                            onclick={async () => {
                                setPreRedirectModalState(false);
                                try {
                                    if (!missionTaskCompleted) {
                                        setTaskCompleted(true);
                                        await completeTask(missionTask?._id);
                                    }
                                    const action = {
                                        type: 'service',
                                        resource: currentServiceId,
                                    };
                                    await addAction(missionTask?._id, action);
                                } catch (err) {
                                    console.log(err);
                                }
                                openWindow(selectedServiceUrl, true);
                            }}
                        >
                            J'ai Compris !
                        </Button>
                    </ButtonContainer>
                </div>
            </GenericModal>
        </div>
    );
};

export default TaskPresetCard;
