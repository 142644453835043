export const serviceIds = {
    orientoi: '60796546a2be8fc2e9b02027',
    jobready: '5fb272090ee6207b28216bab',
    inokufu: '60796606a2be8fc2e9b0202f',
    pitangoo: '62695312b3417319920577cb',
    curiose: '62692504b341731992056fe0',
    becomino: '62669514ea1a363d856b4c5b',
    justeSalaire: '639b43cf72b2d6771b130d0b',
    mySoftSkills: '638a27cb84f56264458770ca',
    headai: '63ff6728aa769c32564bb19c',
};
