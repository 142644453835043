import React, { useState, useEffect } from 'react';
import { getCompletedMissions } from '../../../libs/gamification/missions';

import HeaderWithNavBar from '../../../Components/Header/HeaderWithNavBar';
import MissionCard from '../../../Components/Cards/MissionCard/MissionCard';
import CardObjectiveAdventure from '../../../Components/Cards/CardObjectiveAdventure/CardObjectiveAdventure';
import GenericModal from '../../../Components/Modal/GenericModal/GenericModal';

import styles from './missionsPage.module.scss';
import { appImageAssets } from '../../../data/enums/appImageAssets';
import { useUserMissions } from '../../../hooks/missions/useUserMissions';
import TextContainer from '../../../Components/Text/TextContainer/TextContainer';
import SkeletonMissionCard from '../../../Components/Skeletons/SkeletonsTemplate/SkeletonMissonCard/SkeletonMissionCard';
import { useAuth } from '../../../hooks/useAuth';
import { useClient } from '../../../hooks/useClient';
import { useObjectives } from '../../../hooks/useObjectives';

const MissionsPage = () => {
    const { user } = useAuth();
    const { updateUser } = useClient();
    const [, objectivesLoading, objectives] = useObjectives();

    const [buttonAnimation, setButtonAnimation] = useState(false);

    const { userMissions, userMissionsLoaded } = useUserMissions();

    const [openInformationModal, setOpenInformationModal] = useState(false);

    useEffect(() => {
        let isMounted = true;
        if (!user || !user.gamification) return;
        if (!localStorage.getItem('hasSeenGamificationInfoModal')) setOpenInformationModal(true);

        if (!isMounted) return;

        sessionStorage.removeItem('isReturningFromTaskPage');

        return () => {
            isMounted = false;
        };
    }, [user]);

    const closeInformationModal = () => {
        localStorage.setItem('hasSeenGamificationInfoModal', true);
        setOpenInformationModal(false);
    };

    const updateMainObjective = (objective) => {
        updateUser({ mainObjective: objective });
    };

    const objectiveDetails = () => {
        if (!user || objectivesLoading || !objectives.length) return <span>En cours de traitement</span>;

        const objective = objectives.find((obj) => obj._id === user?.gamification?.mainObjective);

        return objective;
    };

    return (
        <>
            <HeaderWithNavBar tabActive={1} />

            <div className={styles.page}>
                <GenericModal
                    open={openInformationModal}
                    closeModal={closeInformationModal}
                    image={appImageAssets.icon.info}
                >
                    <div className={styles.modal}>
                        <h3>Bienvenue sur votre espace VisionsGalaxy</h3>
                        <p>
                            Effectuez des missions et tâches pour vous aider à accomplir l'objectif que vous avez choisi
                            précédemment. <br />
                            <br />
                            Une mission comporte plusieurs tâches et chaque tâche vous rapportera des VisionsStars que
                            vous pourrez ensuite échanger contre des cadeaux et récompenses !
                        </p>
                    </div>
                </GenericModal>

                <div className={styles.container}>
                    <div className={styles.content}>
                        <CardObjectiveAdventure
                            mainObjective={objectiveDetails()}
                            updateMainObjective={updateMainObjective}
                        />

                        {userMissions &&
                            userMissions.length !== 0 &&
                            userMissions.map((mission, index) => {
                                if (!mission.isCompleted) {
                                    return (
                                        <MissionCard
                                            key={index}
                                            mission={mission}
                                            numberMission={index + 1}
                                            animationBtn={index === 0 ? buttonAnimation : null}
                                        />
                                    );
                                } else {
                                    return <div key={index}></div>;
                                }
                            })}
                        {!userMissionsLoaded && <SkeletonMissionCard />}
                        {userMissionsLoaded && userMissions?.length === 0 && (
                            <TextContainer>Il n'y a pas encore de missions associées à votre objectif</TextContainer>
                        )}
                    </div>

                    <div className={styles.col}>
                        {!userMissionsLoaded && <SkeletonMissionCard completed />}
                        {getCompletedMissions(userMissions).length !== 0 && (
                            <div className={styles.missionsFinished}>
                                <h3>Missions terminées</h3>
                                {getCompletedMissions(userMissions).map((mission, index) => (
                                    <MissionCard
                                        key={index}
                                        mission={mission}
                                        numberMission={index + 1}
                                        animationBtn={index === 0 ? buttonAnimation : null}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default MissionsPage;
