import React, { useState, useEffect } from 'react';

import { stepsContentOnboarding } from '../../../../data/project/contentOnboarding';

import styles from './StepsContentOnboarding.module.scss';

const StepsContentOnboarding = ({ step, animationSlide }) => {
    const [content, setContent] = useState({});

    useEffect(() => {
        switch (step) {
            case 1:
                setContent(stepsContentOnboarding.welcome);
                break;
            case 2:
                setContent(stepsContentOnboarding.information);
                break;
            case 3:
                setContent(stepsContentOnboarding.matchs);
                break;
            default:
                return;
        }
    }, [step]);

    return (
        <div className={`${styles.container} ${animationSlide ? styles.slide : ''}`}>
            <div className={styles.header}>
                <h1 className={styles.title}>
                    {content.title}
                </h1>
                {content.VG !== '' && <p className={styles.VG} dangerouslySetInnerHTML={{ __html: content.VG }}></p>}
                <img src={content.img} alt="" style={{ maxWidth: !content.VG && '300px' }} />
            </div>
            <p className={styles.text} dangerouslySetInnerHTML={{ __html: content.text }}></p>
        </div>
    );
};

export default StepsContentOnboarding;
