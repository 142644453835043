import { OBJECTIVE_RESEARCH_TYPE } from '../enums/objectiveResearchType';
import { serviceIds } from '../enums/services';

const YOUNG_SERVICES = [serviceIds.orientoi];
const OLD_SERVICES = [serviceIds.justeSalaire];

const DEMO_SERVICES = [serviceIds.jobready, serviceIds.orientoi];

const IFRAME_MISSION = '63ff5f156257e7a0103766f0';
const JS_MSS_MISSION = '6408aeb82ac8e942dcf17e40';
const OT_JR_MISSION = '63ff53ae6257e7a0103766d1';
const PARCOURSUP_MISSION = '63ff74776257e7a01037672c';
const OT_MISSION_SDN = '6411fcb425dbc5338812ed43';

const YOUNG = {
    services: YOUNG_SERVICES,
    missions: [OT_MISSION_SDN, IFRAME_MISSION, PARCOURSUP_MISSION],
};
const OLD = {
    services: OLD_SERVICES,
    missions: [JS_MSS_MISSION, IFRAME_MISSION, PARCOURSUP_MISSION], // Don't show last mission
};

export const PRESET_MISSION_CONFIG = {
    JOB: {
        '16-18': YOUNG,
        '18-25': YOUNG,
        '25-30': OLD,
        '30+': OLD,
    },
    FORMATION: YOUNG,
    Lycéen: YOUNG,
};

export const getPresetFromAgeGroupAndSituation = (situation, ageGrp, specific = null) => {
    /**
     * Specific to the Semaine du numérique context
     */
    if (specific === 'sdn') {
        return {
            services: [serviceIds.orientoi],
            missions: [OT_MISSION_SDN, IFRAME_MISSION, PARCOURSUP_MISSION],
        };
    }

    if (specific === 'demo') {
        return {
            services: DEMO_SERVICES,
            missions: [OT_JR_MISSION, IFRAME_MISSION, PARCOURSUP_MISSION],
        };
    }

    if (situation === OBJECTIVE_RESEARCH_TYPE.job) return PRESET_MISSION_CONFIG[situation][ageGrp];

    return PRESET_MISSION_CONFIG[situation];
};
