import React, { useState, useEffect } from 'react';
import MissionsPage from '../views/Missions/MissionsPage/MissionsPage';
import MissionPresetPage from '../views/Missions/MissionPresetPage/MissionPresetPage';
import { useAuth } from '../hooks/useAuth';
import SelectObjectiveMissionPage from '../views/Missions/SelectObjectiveMissionPage/SelectObjectiveMissionPage';
import { useNavigate } from 'react-router';
import { appLinks } from '../data/enums/appLinks';
import { OBJECTIVE_RESEARCH_TYPE } from '../data/enums/objectiveResearchType';
import { IncompleteProfilePage } from '../views/Profile/IncompleteProfilePage/IncompleteProfilePage';

const ContextMissionsTypeRouter = () => {
    const { user } = useAuth();

    const navigate = useNavigate();

    const researchType = localStorage.getItem('researchType');
    const [presetCompleted, setPresetCompleted] = useState(false);
    const [objectiveSelected, setObjectiveSlected] = useState(false);

    const updateMissionPreset = () => {
        setPresetCompleted(true);
    };

    const updateObjectiveSelected = () => {
        setObjectiveSlected(true);
    };

    useEffect(() => {
        if (
            user.experiencesInfo.situation !== OBJECTIVE_RESEARCH_TYPE.student &&
            !localStorage.getItem('researchType')
        ) {
            navigate(appLinks.config);
            return;
        }
    }, [user, navigate]);

    if (!user.profile || !user.profile.age_group) return <IncompleteProfilePage missingFields={['age_group']} />;

    if (presetCompleted && !objectiveSelected)
        return <SelectObjectiveMissionPage objectiveSelected={updateObjectiveSelected} />;

    if (!presetCompleted && user.experiencesInfo.situation === OBJECTIVE_RESEARCH_TYPE.student)
        return <MissionPresetPage situation={OBJECTIVE_RESEARCH_TYPE.student} presetCompleted={updateMissionPreset} />;

    if (
        !presetCompleted &&
        user.experiencesInfo.situation !== OBJECTIVE_RESEARCH_TYPE.student &&
        researchType === OBJECTIVE_RESEARCH_TYPE.job
    )
        return <MissionPresetPage situation={OBJECTIVE_RESEARCH_TYPE.job} presetCompleted={updateMissionPreset} />;

    if (
        !presetCompleted &&
        user.experiencesInfo.situation !== OBJECTIVE_RESEARCH_TYPE.student &&
        researchType === OBJECTIVE_RESEARCH_TYPE.formation
    )
        return (
            <MissionPresetPage situation={OBJECTIVE_RESEARCH_TYPE.formation} presetCompleted={updateMissionPreset} />
        );

    return <MissionsPage />;
};

export default ContextMissionsTypeRouter;
